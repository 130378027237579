import { useContext, useEffect, useState, useMemo } from 'react'

import { Routes, Route } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'
import { CSVLink } from "react-csv";

function RowGetViewTableLong(passdata) {
    const { tableGet } = useContext(MyContext);

    const initialState = {
        // url: passdata.url,
        success: true,
        headdata: [],
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    useEffect(() => tableGet(passdata.url)
        .then((data) => {
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    headdata: Object.keys(data.tabledata[0]),
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [passdata])

    const data = useMemo(() => state.tabledata, [state.tabledata])

    return (state.success) &&
        <Routes>
            <Route path="/" element={
                <div className="col-md-2" style={{ padding: "0", marginRight: "1%" }}>
                    <CSVLink className="form-control btn btn-primary" data={data} filename={passdata.text + ".csv"}>{passdata.text}</CSVLink>
                </div>
            } />
        </Routes>
    // : <>{state.errorMsg}</>



};

export default RowGetViewTableLong
