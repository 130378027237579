import React, { createContext, Component } from "react";
import axios from 'axios'
export const MyContext = createContext();


// console.log(window.location.host)

// window.location.hostname
// const domainUrl = window.location.host === "localhost:3000" ? "http://localhost/data/vwcv/" : "https://vwcv.ienterprise.com.hk/data"
const domainUrl = window.location.host === "localhost:3000" ? "http://" + window.location.hostname + "/data/vwcv/" : "https://vwcv.ienterprise.com.hk/data"

// console.log(window.location.hostname)
// Define the base URL
const Axios = axios.create({
    // baseURL: 'http://192.168.88.103/data/',
    // baseURL: 'http://localhost/php-login-registration-api/',
    // baseURL: 'http://localhost/data/vwcv/',
    baseURL: 'https://vwcv.ienterprise.com.hk/data',
    // baseURL: domainUrl,

});

class MyContextProvider extends Component {
    constructor() {
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin: true,
        isAuth: false,

        UserId: null,
        theUser: null,
    }

    // Toggle between Login & Signup page
    toggleNav = () => {
        const showLogin = !this.state.showLogin;
        this.setState({
            ...this.state,
            showLogin
        })
    }

    // On Click the Log out button
    logoutUser = () => {
        // const loginToken = localStorage.getItem('loginToken');

        // if (loginToken) {
        //     Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
        //     Axios.get('logout-record.php');
        // }

        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth: false
        })
    }



    registerUser = async (user) => {

        // Sending the user registration request
        const register = await Axios.post('register.php', {
            name: user.name,
            email: user.email,
            ac: user.ac,
            password: user.password
        });

        return register.data;
    }


    loginUser = async (user) => {

        // Sending the user Login request
        const login = await Axios.post('login.php', {
            ac: user.ac,
            password: user.password
        });
        return login.data;
    }

    // Checking user logged in or not
    isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');

        // If inside the local-storage has the JWT token
        if (loginToken) {

            //Adding JWT token to axios default header
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;

            // Fetching the user information
            const { data } = await Axios.get('user-info.php');

            // If user information is successfully received
            if (data.success && data.user) {
                this.setState({
                    ...this.state,
                    isAuth: true,
                    theUser: data.user
                });
            }

        }
    }

    //Get table data
    tableGet = async (phpurl) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const { data } = await Axios.get(phpurl);
            // if (data.success) { return data.tabledata; } else { return data.message; } 
            return data;
        }
    }

    getTableType = async (phpurl, tablename) => {
        // console.log(fielddata, fieldname, tablename)
        const newaxios = await Axios.post(phpurl, {
            tablename: tablename,
        });

        // console.log(newaxios)

        return newaxios.data;
    }
    getTableTypeByParam = async (phpurl, tablename, param) => {
        // console.log(fielddata, fieldname, tablename)
        const newaxios = await Axios.post(phpurl, {
            tablename: tablename,
            param: param,
        });

        // console.log(newaxios)

        return newaxios.data;
    }

    updateTableType = async (phpurl, tablename, id, state) => {
        // console.log(fielddata, fieldname, tablename)
        const newaxios = await Axios.post(phpurl, {
            tablename: tablename,
            id: id,
            state: state,
        });

        // console.log(newaxios)

        return newaxios.data;
    }

    getTableWithField = async (fielddata, fieldname, tablename) => {
        // console.log(fielddata, fieldname, tablename)
        const newaxios = await Axios.post('getTableWithField.php', {
            fielddata: fielddata,
            fieldname: fieldname,
            tablename: tablename,
        });

        // console.log(newaxios)

        return newaxios.data;
    }


    // msDataGet = async (id) => {
    //     const newaxios = await Axios.post('admin-project-create.php', {
    //         id: id,
    //     });

    //     return newaxios.data;
    // }

    //admin function
    //porject create
    projectCreate = async (formdata) => {

        // Sending the user registration request
        const newaxios = await Axios.post('admin-project-create.php', {
            name: formdata.name,
            note: formdata.note,
        });

        return newaxios.data;
    }

    projectCreateResult = async (formdata) => {

        // Sending the user registration request
        // console.log(formdat)
        const newaxios = await Axios.post('admin-project-result-create.php', {
            description: formdata.description,
            text: formdata.text,
        });

        return newaxios.data;
    }

    projectResultActiveGet = async (formdata) => {

        // Sending the user registration request
        console.log(formdata)
        const newaxios = await Axios.post('admin-project-result-type-get.php', {
            porject_id: formdata,
        });
        console.log(newaxios.data)
        return newaxios.data;
    }

    projectResultAdd = async (formdata) => {

        // Sending the user registration request
        // console.log(formdat)
        const newaxios = await Axios.post('admin-project-add-result.php', {
            description: formdata.description,
            text: formdata.text,
        });

        return newaxios.data;
    }

    //new record from porject 
    newTicketRecord = async (formdata) => {

        // Sending the user registration request
        console.log(formdata)
        // console.log(formdata.manual_start_info)
        // console.log('myr',formdata.manual_start_info.ms_recharged_result)
        // console.log('myt',formdata.manual_start_info.ms_refund_type)

        const thedate = new Date(+new Date(`${formdata.bookingDateYear}-${parseInt(formdata.bookingDateMonth) + 1}-${formdata.bookingDateDay} ${formdata.bookingTime}:00`) + 8 * 3600 * 1000).toISOString();
        // console.log(thedate)
        const newtheDates = thedate.substring(0, thedate.length - 5);
        // console.log(newtheDates)

        const newaxios = await Axios.post('ticket-record-new.php', {
            // porject_id: formdata.porject_id,
            // phone: formdata.phone,

            inCallTime: formdata.inCallTime,
            type: formdata.type,
            issueType: formdata.issueType,
            bookingDate: newtheDates,
            site: formdata.site,

            name: formdata.name,
            phone: formdata.phone,
            symptomType: formdata.symptomType,
            symptom: formdata.symptom,

            vehicleNo: formdata.vehicleNo,
            model: formdata.model,
            chassis: formdata.chassis,

            notNeedBooking: formdata.notNeedBooking,

        });
        // console.log(newaxios.data);
        return newaxios.data;
    }


    //new record from porject 
    siteClose = async (formdata) => {
        delete (formdata.errorMsg)
        delete (formdata.successMsg)
        delete (formdata.closeData)

        console.log(formdata)
        // const thedate = new Date(+new Date(`${formdata.bookingDateYear}-${parseInt(formdata.bookingDateMonth) + 1}-${formdata.bookingDateDay} ${formdata.bookingTime}:00`) + 8 * 3600 * 1000).toISOString();
        // console.log(thedate)
        // const newtheDates = thedate.substring(0, thedate.length - 5);
        // console.log(newtheDates)

        const newaxios = await Axios.post('site-close.php', {
            // bookingDate: newtheDates,
            closedate: formdata.closedate,
            site: formdata.site,

        });
        // console.log(newaxios.data);
        return newaxios.data;
    }

    siteSlotClose = async (formdata) => {
        delete (formdata.errorMsg)
        delete (formdata.successMsg)
        delete (formdata.closeDates)

        console.log(formdata)

        const newaxios = await Axios.post('site-slot-close.php', {
            // bookingDate: newtheDates,
            closedate: formdata.closedate,
            site: formdata.site,
            closeslot: formdata.closeslot,

        });
        // console.log(newaxios.data);
        return newaxios.data;
    }


    newOnHoldBookRecord = async (formdata) => {

        const thedate = new Date(+new Date(`${formdata.bookingDateYear}-${parseInt(formdata.bookingDateMonth) + 1}-${formdata.bookingDateDay} ${formdata.bookingTime}:00`) + 8 * 3600 * 1000).toISOString();
        // console.log(thedate)
        const newtheDates = thedate.substring(0, thedate.length - 5);
        // console.log(newtheDates)

        const newaxios = await Axios.post('on-hold-record-new.php', {
            // porject_id: formdata.porject_id,
            // phone: formdata.phone,

            inCallTime: formdata.inCallTime,
            type: formdata.type,
            issueType: formdata.issueType,
            bookingDate: newtheDates,
            site: formdata.site,

            name: formdata.name,
            phone: formdata.phone,
            symptomType: formdata.symptomType,
            symptom: formdata.symptom,

            vehicleNo: formdata.vehicleNo,
            model: formdata.model,
            chassis: formdata.chassis,

        });
        // console.log(newaxios.data);
        return newaxios.data;
    }



    deleteTicket = async (id, newstate) => {
        // console.log(id)
        // Sending the user registration request
        const newaxios = await Axios.post('ticket-record-delete.php', {
            id: id,
            newstate: newstate,
        });

        return newaxios.data;
    }


    render() {
        const contextValue = {
            //base
            rootState: this.state,
            toggleNav: this.toggleNav,
            isLoggedIn: this.isLoggedIn,
            registerUser: this.registerUser,
            loginUser: this.loginUser,
            logoutUser: this.logoutUser,

            //Get table
            tableGet: this.tableGet,
            getTableType: this.getTableType,
            getTableTypeByParam: this.getTableTypeByParam,
            updateTableType: this.updateTableType,
            // msDataGet: this.msDataGet,

            //admin
            // projectCreate: this.projectCreate,
            // projectCreateResult: this.projectCreateResult,
            // projectResultActiveGet: this.projectResultActiveGet,
            // projectResultAdd: this.projectResultAdd,


            //project
            // projectGet: this.projectGet, 
            newTicketRecord: this.newTicketRecord,

            siteClose: this.siteClose,
            siteSlotClose: this.siteSlotClose,

            newOnHoldBookRecord: this.newOnHoldBookRecord,
            getTableWithField: this.getTableWithField,
            deleteTicket: this.deleteTicket,


        }
        return (
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;