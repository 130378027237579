function RowDataList(row_data) {
    return (
        <datalist id={row_data.id} onChange={row_data.onChange}>
            {/* {console.log(row_data)} */}
            {row_data.data.map((rows) =>
                <option
                    // value={rows.RegNo}
                    value={rows.Id + ', ' + rows.RegNo + ', ' + rows.Description + ', ' + rows.Chassis + ', ' + rows.DeliveryDate + ', ' + rows.ManufacturingYear}
                    key={rows.Id}
                    data-id={rows.Id}
                    data-vehicle={rows.RegNo}
                    data-model={rows.Description}
                    data-chassis={rows.Chassis} />

            )}
        </datalist>
    )
}
export default RowDataList;

    // <select id={row_data.id} name={row_data.id} value={row_data.value} onChange={row_data.onChange} className="form-select">
    //     <option value='' key=''>Please select</option>
    //         <option
    //             value={rows.Id}
    //             key={rows.Id}
    //             data-name={rows.EngName} >
    //             {rows.EngName}
    //         </option>
