function RowSelect(row_select) {
    return (
        <div>
            {/* {console.log(row_select)} */}
            {/* <label htmlFor={row_select.id} className="form-label">{row_select.text} :</label> */}
            <select id={row_select.id} name={row_select.id} value={row_select.value} onChange={row_select.onChange} className="form-select">
                <option value='' key=''>Please select</option>
                {row_select.data.map((rows) =>
                    <option
                        value={rows.Id}
                        key={rows.Id}
                        data-name={rows.EngName} >
                        {rows.EngName}
                    </option>
                )}
            </select>
        </div>
    )
}
export default RowSelect;