import { Routes, Route, Link } from "react-router-dom";
import RowGetViewTableLong from '../components/RowGetViewTableLong'
import RowGetViewTableCSV from '../components/RowGetViewTableCSV'
import RowGetViewTable from "../components/RowGetViewTable";

import SelectColumnFilter from "../components/ReactTable/SelectColumnFilter";
// import { useContext } from 'react'
// import { MyContext } from '../contexts/MyContext'

import AccessibleNavigationAnnouncer from "../components/AccessibleNavigationAnnouncer";

function Report() {

  // const { rootState } = useContext(MyContext);
  // const { theUser } = rootState;

  const ticketcolumns = [
    { Header: 'Id', accessor: 'Id', disableFilters: true, },


    // {
    //   Header: 'Form Data', Cell: rows => <>
    //     {`N:${rows.row.original.InCallDate}`}<br />
    //     {`M:${rows.row.original.Service}`}<br />
    //     {`C:${rows.row.original.Site}`}<br />
    //   </>,
    // },


    { Header: 'InCallDate', accessor: 'InCallDate', },


    {
      Header: 'Service & Issue', Cell: rows => <>

        {/* {`Dat:${rows.row.original.InCallDate}`}<br /> */}
        {`Ser:${rows.row.original.Service}`}<br />
        {`Iss:${rows.row.original.Issue}`}<br />

      </>,
    },
    // { Header: 'Service', accessor: 'Service', },
    // { Header: 'Issue', accessor: 'Issue', },



    // { Header: 'Site', accessor: 'Site', },
    { Header: 'Site', Cell: rows => <>{rows.row.original.SiteId == 15 ? 'KC' : 'SS'}</>, },

    {
      Header: 'Client', Cell: rows => <>

        {`${rows.row.original.VehicleNo}`}<br />
        {`${rows.row.original.Name}`}<br />
        {`${rows.row.original.Phone}`}<br />

      </>,
    },
    // { Header: 'Name', accessor: 'Name', },
    // { Header: 'Phone', accessor: 'Phone', },
    // { Header: 'SymptomType', accessor: 'SymptomType', },
    // { Header: 'Symptomo', accessor: 'Symptom', },
    { Header: 'BookingDate', accessor: 'BookingDate', },

    { Header: 'Symptom', Cell: rows => <>{'[' + rows.row.original.SymptomType + '] ' + rows.row.original.Symptom}</>, },

    {
      Header: 'Vehicle', Cell: rows => <>
        {`N:${rows.row.original.VehicleNo}`}<br />
        {`M:${rows.row.original.Model}`}<br />
        {`C:${rows.row.original.Chassis}`}<br />
      </>,
    },


    // { Header: 'VehicleNo', accessor: 'VehicleNo', },
    // { Header: 'Model', accessor: 'Model', },
    // { Header: 'Chassis', accessor: 'Chassis', },
    { Header: 'User', accessor: 'User', },
    // {
    //   Header: 'Details', Cell: rows =>
    //     <Link to={"/report/tickentreport/" + rows.row.original.Id}>More</Link>
    // },
  ]

  return (
    <>
      <AccessibleNavigationAnnouncer page="Report" />

      {/* // <div className="row justify-content-md-center"> */}
      <div className="row">
        <nav>
          <Link to="tickentreport">Ticket Report</Link>
        </nav>
        <h1>Report</h1>
        <Routes>
          {/* <Route path="/index.html" element={<Home />} /> */}
          <Route index element={
            <>
              <div className='row' style={{ marginBottom: "1%" }}>
                <RowGetViewTableCSV url="ticket-record-get-all.php" text="Download All Data" />
                {/* {(theUser.type <= 1) ? <RowGetViewTableCSV url="ticket-record-get-week.php" text="Download last 7 day Data" /> : null} */}
                {/* <RowGetViewTableCSV url="ticket-record-get-week.php" text="Download last 7 day Data" /> */}
              </div>
              <div className="row">
                <RowGetViewTableLong url="ticket-record-get-all.php" col={ticketcolumns} />
                {/* <RowGetViewTable url="ticket-record-get-all.php" col={ticketcolumns} /> */}
              </div>
            </>
          } />

          <Route path="/tickentreport/*" index element={
            <>
              <div className='row' style={{ marginBottom: "1%" }}>
                {/* <RowGetViewTableCSV url="ticket-record-get-all.php" text="Download All Data" /> */}
                {/* {(theUser.type <= 1) ? <RowGetViewTableCSV url="ticket-record-get-week.php" text="Download last 7 day Data" /> : null} */}
                {/* <RowGetViewTableCSV url="ticket-record-get-week.php" text="Download last 7 day Data" /> */}
              </div>
              <div className="row">
                <RowGetViewTableLong url="ticket-record-get-all.php" col={ticketcolumns} />
                {/* <RowGetViewTable url="ticket-record-get-all.php" col={ticketcolumns} /> */}

              </div>
            </>
          } />
        </Routes>

      </div>
    </>
  );
}

export default Report;
