import MyContextProvider from './contexts/MyContext';
import RouterDom from './router/RouterDom'

function App() {
  return (
    <MyContextProvider>
      <RouterDom />
    </MyContextProvider>
  );
}

export default App;