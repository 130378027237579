import { useContext, useState, useEffect } from 'react';
import { Routes, Route, NavLink, useNavigate, Link } from 'react-router-dom';

import { MyContext } from '../contexts/MyContext';
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";

// import NewTicketFAQ from '../components/NewTicketFAQ'

// import RowTable from '../../components/rows/RowTable'
import RowText from '../components/rows/RowText';
import RowTextArea from '../components/rows/RowTextArea';
import RowCheckbox from '../components/rows/RowCheckbox';
// import RowPorjectFromResult from '../../components/RowPorjectFromResult'

// import RowSelect from '../components/rows/RowSelect';

import RowGetViewTableToSelect from "../components/RowGetViewTableToSelect"
import RowGetViewTableToSelectByText from "../components/RowGetViewTableToSelectByText"
import RowGetViewTableToCheckByText from "../components/RowGetViewTableToCheckByText"
import RowGetViewTableToDataList from "../components/RowGetViewTableToDataList"
// import ItemRadioText from '../../components/rows/RowItemRadioText';
import './form.css'
import AccessibleNavigationAnnouncer from "../components/AccessibleNavigationAnnouncer";

// import RowCSS from '../../components/RowCSS'

function NewTicket(baseformdata) {
    const navigate = useNavigate();
    const { newTicketRecord, getTableType, newOnHoldBookRecord } = useContext(MyContext);
    const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
    const nowISO = theTime.substring(0, theTime.length - 5)
    const now = new Date();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthsdays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


    const initialFormData = {

        formInfo: {
            inCallTime: nowISO,
            type: '',
            issueType: '',

            bookingDateYear: now.getFullYear(),
            bookingDateMonth: now.getMonth(),
            bookingDateDay: '',
            bookingTime: '08:00',

            site: '',

            name: '',
            phone: '',
            symptomType: '',
            symptom: '',

            vehicleNo: '',
            model: '',
            chassis: '',
            deliveryDate: '',
            manufacturingYear: '',

            subook: false,
            notNeedBooking: false,
        },
        bookingData: [],
        hkHoliday: [],
        siteCloseDay: [],
        errorMsg: '',
        successMsg: '',
    }
    const [FormData, setFormData] = useState(initialFormData);

    const tableUrl = ["getTableRegData.php", "ticket_booking_count", "hk_holiday", "site_close_day"]

    useEffect(() => {

        const getSomeData = async () => {
            const data = await getTableType(tableUrl[0], tableUrl[1])
            const data2 = await getTableType(tableUrl[0], tableUrl[2])
            const data3 = await getTableType(tableUrl[0], tableUrl[3])
            // console.log("1", data)
            // console.log("2", data2)


            var holiday = []
            data2.tabledata.map((item) => {
                holiday.push(item.Holiday)
            })
            var close_day = []
            data3.tabledata.map((item) => {
                close_day.push(item)
            })

            setFormData({
                ...FormData,
                bookingData: data.tabledata,
                hkHoliday: holiday,
                siteCloseDay: close_day,
            });

        }
        getSomeData()
    }, [tableUrl[0], tableUrl[1]])

    // On Submit the Porject Form
    const submitForm = async (event) => {
        event.preventDefault();
        // console.log("submit", FormData.formInfo)
        const data = await newTicketRecord(FormData.formInfo);
        if (data.success) {
            setFormData({
                ...initialFormData,
                successMsg: data.message,
            })
            navigate('/report')
        }
        else {
            setFormData({
                ...FormData,
                successMsg: '',
                errorMsg: data.message
            });
        }
    }

    // console.log(FormData.formInfo)

    const holdTimeForm = async (event) => {
        event.preventDefault();
        // console.log("submit", FormData.formInfo)
        console.log("holdTimeForm")
        // const data = await newTicketRecord(FormData.formInfo);
        // if (data.success) {
        //     setFormData({
        //         ...initialFormData,
        //         successMsg: data.message,
        //     })
        //     navigate('/report')
        // }
        // else {
        //     setFormData({
        //         ...FormData,
        //         successMsg: '',
        //         errorMsg: data.message
        //     });
        // }
    }

    // console.log(FormData)

    const onChangeValue = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                [e.target.name]: e.target.value,
            }
        });
        // console.log(FormData.manual_start_info)
    }

    const onChangeSite = (e) => {
        const time = e.target.value == 16 ? '08:00' : '09:00'
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                [e.target.name]: e.target.value,
                bookingTime: time,
            }
        });
        // console.log(FormData.manual_start_info)
    }


    const changeToJSdate = (day) => {
        return `${parseInt(FormData.formInfo.bookingDateYear)}-${(parseInt(FormData.formInfo.bookingDateMonth) + 1).toString().padStart(2, '0')}-${(day + 1).toString().padStart(2, '0')}`
    }

    const showTheBookingDay = (day) => {
        return !FormData.bookingData.some(
            x => x.BookingDate === changeToJSdate(day)
                && x.SiteId === FormData.formInfo.site
                && x.CanBook === "N"
        )
    }

    const checkHoliday = (day) => {
        let theday = getTheWeekDay(day)
        return theday != 0 && theday != 6
    }

    const getTheWeekDay = (day) => {
        let theday = new Date(`${parseInt(FormData.formInfo.bookingDateYear)}-${parseInt(FormData.formInfo.bookingDateMonth) + 1}-${(day + 1).toString().padStart(2, '0')}`).getDay()
        return theday
    }

    const checkRedDayHoliday = (day) => {
        const daylist = FormData.hkHoliday
        return !daylist.some(x => x === changeToJSdate(day))
    }

    const checkSiteCloseDay = (day) => {
        return !FormData.siteCloseDay.some(
            x => x.Holiday === changeToJSdate(day)
                && x.SiteId === FormData.formInfo.site
        )
    }

    const checkTheBookingDay = (day) => {
        return showTheBookingDay(day) && checkHoliday(day) && checkRedDayHoliday(day) && checkRedDayHoliday(day) && checkSiteCloseDay(day)
    }


    const onSelectDataList = (e) => {
        const datal = e.target.value.split(", ")

        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                vehicleNo: e.target.value.includes(", ") ? datal[1] === "undefined" ? "" : datal[1] : e.target.value,
                model: datal[2] === "undefined" ? "" : datal[2],
                chassis: datal[3] === "undefined" ? "" : datal[3],
                deliveryDate: datal[4] === "undefined" ? "" : datal[4],
                manufacturingYear: datal[5] === "undefined" ? "" : datal[5],
            }
        });
    }

    const handleOnChange = (e) => {
        setFormData({
            ...FormData,
            formInfo: {
                ...FormData.formInfo,
                // [e.target.name]: !FormData.formInfo.subook,
                [e.target.name]: !FormData.formInfo[e.target.name],
            }
        });
    };

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (FormData.errorMsg) {
        errorMsg = <div className="error-msg">{FormData.errorMsg}</div>;
    }
    if (FormData.successMsg) {
        successMsg = <div className="success-msg">{FormData.successMsg}</div>;
    }


    return (
        <>
            <AccessibleNavigationAnnouncer page="New Ticket" />

            <h1>New Ticket</h1>

            {/* <div className="row newticket pt-2 mb-2">
                <form onSubmit={submitForm} noValidate autoComplete='off'>
                </form>
            </div> */}
            <div className="row newticket p-2">

                <form onSubmit={submitForm} noValidate autoComplete='off'>
                    <h2>基本資料</h2>
                    <section className="mb-4 mt-3 p-2">
                        <div className="row">
                            <h3>表格資料</h3>
                        </div>
                        <div className='row mb-3'>
                            <div className='col'>
                                <RowText
                                    id='inCallTime'
                                    type='datetime-local'
                                    value={FormData.formInfo.inCallTime}
                                    onChange={onChangeValue}
                                    text='來電時間'
                                    placeholder='來電時間'
                                    // required='required'
                                    step={1}
                                />
                            </div>
                            <div className='col'>
                                <label className='form-label'>服務<span style={{ color: "red" }}> * </span>:</label>
                                <RowGetViewTableToSelectByText
                                    url="getTableTypeData.php"
                                    tablename="service"
                                    id="type"
                                    value={FormData.formInfo.type}
                                    onChange={onChangeValue}
                                />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col'>
                                <label className='form-label'>問題類別<span style={{ color: "red" }}> * </span>:</label>
                                <RowGetViewTableToSelectByText
                                    url="getTableTypeData.php"
                                    tablename="issue"
                                    id="issueType"
                                    value={FormData.formInfo.issueType}
                                    onChange={onChangeValue}
                                />
                            </div>
                            <div className='col'>
                                <label className='form-label'>維修廠<span style={{ color: "red" }}> * </span>:</label>
                                <RowGetViewTableToSelectByText
                                    url="getTableTypeData.php"
                                    tablename="site"
                                    id="site"
                                    value={FormData.formInfo.site}
                                    onChange={onChangeSite}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <label className='form-label'>Symptom Type :<span style={{ color: "red" }}> * </span>:</label>

                                <RowGetViewTableToSelectByText
                                    url="getTableTypeData.php"
                                    tablename="symptom"
                                    id="symptomType"
                                    value={FormData.formInfo.symptomType}
                                    onChange={onChangeValue}
                                />
                                {/* <RowGetViewTableToCheckByText
                                url="getTableTypeData.php"
                                tablename="symptom"
                                id="symptomType"
                                value={FormData.formInfo.symptomType}
                                onChange={onChangeValue}
                            /> */}

                                <RowCheckbox
                                    id='notNeedBooking'
                                    name='notNeedBooking'
                                    value='bookswitch'
                                    onChange={handleOnChange}
                                    checked={FormData.formInfo.notNeedBooking}
                                    text='Not Booking'
                                />
                            </div>
                            {!FormData.formInfo.notNeedBooking && (parseInt(FormData.formInfo.symptomType) === 19
                                || parseInt(FormData.formInfo.symptomType) === 20
                                || parseInt(FormData.formInfo.symptomType) === 21
                            ) &&
                                <div className='col'>
                                    <div className='row'>
                                        <label className='form-label'>Booking Time :<span style={{ color: "red" }}> * </span>:</label>
                                    </div>

                                    <label className='form-label'>Date :</label>
                                    <select name="bookingDateYear" id="bookingDateYear" onChange={onChangeValue} value={FormData.formInfo.bookingDateYear}>
                                        <option value={now.getFullYear()}>{now.getFullYear()}</option>
                                        <option value={now.getFullYear() + 1}>{now.getFullYear() + 1}</option>
                                        <option value={now.getFullYear() + 2}>{now.getFullYear() + 2}</option>
                                        <option value={now.getFullYear() + 3}>{now.getFullYear() + 3}</option>

                                    </select>

                                    <select name="bookingDateMonth" id="bookingDateMonth" onChange={onChangeValue} value={FormData.formInfo.bookingDateMonth}>
                                        {[...months].map((month, index) =>
                                            <option key={index} value={index}>{month}</option>
                                        )}
                                    </select>

                                    <select name="bookingDateDay" id="bookingDateDay" style={{
                                        backgroundColor: FormData.formInfo.bookingDateDay === "" ? "red" : "white",
                                        color: FormData.formInfo.bookingDateDay === "" ? "white" : "black",
                                    }} onChange={onChangeValue} value={FormData.formInfo.bookingDateDay}>
                                        <option value="" style={{ color: "red" }}>--Day--</option>
                                        {[...Array(monthsdays[FormData.formInfo.bookingDateMonth])].map((x, i) =>
                                            (checkTheBookingDay(i) || FormData.formInfo.subook) && <option key={i} value={i + 1}>{i + 1 + " " + days[getTheWeekDay(i)].substring(0, 3)}
                                            </option>
                                        )}
                                        {(checkTheBookingDay(28) || FormData.formInfo.subook) && parseInt(FormData.formInfo.bookingDateMonth) === 1 && parseInt(FormData.formInfo.bookingDateYear) % 4 === 0 ? <option value={29}>{"29 " + days[getTheWeekDay(28)].substring(0, 3)}</option> : null}
                                    </select>
                                    <label className='form-label'>Time :</label>
                                    <input type="time" name="bookingTime" id="bookingTime" onChange={onChangeValue} value={FormData.formInfo.bookingTime} />

                                    <RowCheckbox
                                        id='subook'
                                        name='subook'
                                        value='book'
                                        onChange={handleOnChange}
                                        checked={FormData.formInfo.subook}
                                        text='Super Booking'
                                    />
                                </div>
                            }
                        </div>
                    </section>

                    <section className="mb-4 p-2">
                        <div className="row">
                            <div className="col">
                                <h3>汽車資料</h3>
                                <RowText
                                    id='vehicleNo'
                                    type='text'
                                    value={FormData.formInfo.vehicleNo}
                                    onChange={onSelectDataList}
                                    text='Vehicle No'
                                    placeholder='AA1234'
                                    list='vehicleNoList'
                                // required='required'
                                />

                                <RowGetViewTableToDataList
                                    url="getTableRegData.php"
                                    tablename="vwcv_reg"
                                    id="vehicleNoList"
                                    onChange={onChangeValue}
                                />

                                <div className='row'>
                                    <label className='form-label col'>Delivery Date : {FormData.formInfo.deliveryDate}</label><br />
                                    <label className='form-label col'>Manufacturing Year : {FormData.formInfo.manufacturingYear}</label>
                                </div>

                                <RowText
                                    id='model'
                                    type='text'
                                    value={FormData.formInfo.model}
                                    onChange={onChangeValue}
                                    text='Model'
                                    placeholder='Null'
                                // required='required'
                                />

                                <RowText
                                    id='chassis'
                                    type='text'
                                    value={FormData.formInfo.chassis}
                                    onChange={onChangeValue}
                                    text='Chassis'
                                    placeholder='Null'
                                // required='required'
                                />
                            </div>

                            <div className="col">
                                <h3>客戶資料</h3>
                                {/* <div className='col'> */}
                                <RowText
                                    id='name'
                                    type='text'
                                    value={FormData.formInfo.name}
                                    onChange={onChangeValue}
                                    text='客戶姓名'
                                    placeholder='mr. xxxx'
                                    required='required'
                                />
                                {/* </div>
                                    <div className='col'> */}
                                <RowText
                                    id='phone'
                                    type='text'
                                    value={FormData.formInfo.phone}
                                    onChange={onChangeValue}
                                    text='來電號碼'
                                    placeholder='12345678'
                                    required='required'
                                />
                                {/* <a href={'https://api.whatsapp.com/send?phone=852' + FormData.formInfo.phone} target="_blank">whatsapp he phone</a> */}
                                {/* </div> */}

                            </div>
                        </div>


                        <div className='row'>
                            <div className='col'>
                                <RowTextArea
                                    id='symptom'
                                    type='text'
                                    value={FormData.formInfo.symptom}
                                    onChange={onChangeValue}
                                    text='Symptom'
                                    placeholder='Enter your description'
                                // required='required'
                                />
                            </div>
                        </div>
                    </section>

                    <div className="mb-4" style={{ color: "red", }}>
                        {errorMsg}
                        {successMsg}
                    </div>
                    <div className="mb-4">
                        {/* <form onSubmit={submitForm} noValidate autoComplete='off'> */}
                        <button type="submit">Submit</button>
                        {/* <button type="reset">reset</button> */}
                        {/* </form> */}
                    </div>
                </form>
            </div>
        </>
    )

};

export default NewTicket