import { useContext, useEffect, useState } from 'react'
import { MyContext } from '../contexts/MyContext';

import RowGetViewTable from '../components/RowGetViewTable'
import AccessibleNavigationAnnouncer from '../components/AccessibleNavigationAnnouncer';
import HomeDailyUpdate from './HomeDailyUpdate';

function Home() {

  const excelurl = 'https://ienterprisedevltd-my.sharepoint.com/:x:/g/personal/iris_wong_ienterprise_com_hk_ext__ienterprisedevltd_onmicrosoft_com/ETU12roljXtEsPQCF11jRAYBK5ZxxWTBSzo545Et-5y6oA?rtime=EuK2dMOm2kg'
  const exceluek = 'https://ienterprisedevltd-my.sharepoint.com/:x:/r/personal/iris_wong_ienterprise_com_hk_ext__ienterprisedevltd_onmicrosoft_com/_layouts/15/Doc.aspx?sourcedoc=%7Bbada3535-8d25-447b-b0f4-02175d634406%7D&action=view'
  const showOnWeb = '&action=embedview'
  const modifyOnWeb = '&wdAllowInteractivity=True'
  const showHeaderFooter = '&wdHideSheetTabs=True'
  const startPointer = '&ActiveCell=A1'
  const showArea = '&Item=A1'
  const typeText = '&AllowTyping=True'


  const excel = 'https://ienterprise406-my.sharepoint.com/:x:/g/personal/admin_ienterprise406_onmicrosoft_com/EUYh4yzFmKREsMXk4WzF7dkBvJsm-E8umE8p3XejMAa2xw?e=U2xKYY'
  const aa = 'https://graph.microsoft.com/v1.0/sites/{siteid}/lists/{listid}/items/273/driveitem/workbook'
  const aab = 'https://graph.microsoft.com/v1.0/sites/{siteid}/lists/{listid}/items/273/driveitem/workbook'





  return (
    <>
      <AccessibleNavigationAnnouncer page="Home" />

      <HomeDailyUpdate />

      <div className="row">
        <hr className='mt-5' />
        {/* <h2>OneDriveTimeTable</h2>
      <h3>Only Perview on this page, if want modift please click the bottom right button</h3> */}
        <a href='https://ienterprisedevltd-my.sharepoint.com/:x:/g/personal/iris_wong_ienterprise_com_hk_ext__ienterprisedevltd_onmicrosoft_com/ETU12roljXtEsPQCF11jRAYBK5ZxxWTBSzo545Et-5y6oA?rtime=EuK2dMOm2kg' target='_blank'>time table</a>
        {/* <RowGetViewTable url="ticket-record-get.php" /> */}
        {/* <iframe src={excelurl + showOnWeb + startPointer + modifyOnWeb} width="100%" height="800px"></iframe> */}
      </div>
    </>
  );
}

export default Home;
