import { useContext, useEffect, useState, useMemo } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'

// import useConfirm from "../components/ConfirmDialog"


export default function ScheduleTable() {
    // const ddd = "2022-11"
    const tableUrl = ["getTableRegData.php", "ticket_booking"]
    let params = useParams();
    const navigate = useNavigate();
    const { getTableType, updateTableType } = useContext(MyContext);
    // const confirm = useConfirm()



    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysch = ['日', '一', '二', '三', '四', '五', '六'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthsdays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
    const nowISO = theTime.substring(0, theTime.length - 5)
    const now = new Date();

    const initialTableData = {
        bookingDateYear: now.getFullYear(),
        bookingDateMonth: now.getMonth(),
        bookingData: [],
        errorMsg: '',
        successMsg: '',
    }
    const [TableData, setTableData] = useState(initialTableData);

    useEffect(() => getTableType(tableUrl[0], tableUrl[1])
        .then((data) => {
            // let paramsdate = new Date(params.thedate);
            let paramsdate = new Date(!params.thedate ? now.getFullYear() + "-" + (now.getMonth() + 1) : params.thedate);
            // if (data != null)
            // console.log(data)
            // console.log(now.getMonth())
            // console.log(!params.thedate ? "yes" : "No")

            if (data.success && data.tabledata) {
                setTableData({
                    ...TableData,
                    bookingData: data.tabledata,
                    bookingDateYear: paramsdate.getFullYear(),
                    bookingDateMonth: paramsdate.getMonth(),
                });
            } else {
                setTableData({
                    ...TableData,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [params.thedate])

    // console.log(TableData)


    const cancelBooking = async (event) => {
        event.preventDefault();
        console.log(event.target.id)
        // navigate('/ScheduleTable', { replace: true });
        const choice = window.confirm(
            "Are you sure you want to delete everything?"
        )

        if (choice) {
            // Perform your dangerous critical action here.
            // console.log("You chose to delete everything.")
            const data = await updateTableType("updateTableData.php", "ticket_booking", event.target.id, 1);
            if (data.success) {
                setTableData({
                    ...initialTableData,
                    successMsg: data.message,
                },
                    // () => { navigate(`/schedule/${now.getFullYear()}-${(now.getMonth() + 1)}`, { replace: true }); }
                )
                // navigate('/ScheduleTable', { replace: true });

                const choice = window.confirm(
                    "It have delete!"
                )
                if (choice) {
                    navigate(`/schedule/${now.getFullYear()}-${(now.getMonth() + 1)}`, { replace: true });
                }
                // window.confirm("It have delete! it have a bug! You want to refresh from your hand!")
            }
            else {
                setTableData({
                    ...TableData,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
            navigate('/schedule', { replace: true });
        }



    }

    const checkReddayHoliday = (day) => {
        // const daylist = ['2022-1-01', '2022-2-01', '2022-2-02', '2022-2-03', '2022-4-05', '2022-4-15'
        //     , '2022-4-16', '2022-4-18', '2022-5-02', '2022-5-09', '2022-6-03', '2022-7-01'
        //     , '2022-9-12', '2022-10-01', '2022-10-04', '2022-12-26', '2022-12-27'
        //     , '2023-1-02'
        // ]

        const daylist = FormData.hkHoliday
        return !daylist.some(x => x === `${parseInt(FormData.formInfo.bookingDateYear)}-${parseInt(FormData.formInfo.bookingDateMonth) + 1}-${(day + 1).toString().padStart(2, '0')}`)


    }

    const ShowTheBookingDay = ({ day, site }) => {
        // console.log(new Date(`${FormData.formInfo.bookingDateYear}-${FormData.formInfo.bookingDateMonth + 1}-${FormData.formInfo.bookingDateDay} ${FormData.formInfo.bookingTime}:00`).getDay())
        return TableData.bookingData.map((x, i) =>
            x.SiteId == site &&
                (new Date(x.BookingTime)).getFullYear() === parseInt(TableData.bookingDateYear) &&
                (new Date(x.BookingTime)).getMonth() === parseInt(TableData.bookingDateMonth) &&
                (new Date(x.BookingTime)).getDate() === parseInt(day + 1) ?
                <li key={i}>
                    <form className='row justify-content-between'>
                        <span className='col'>{x.BookingText}</span>
                        <button className="col-sm-1 m-2" id={x.Id} onClick={cancelBooking} title="Cancel">Del</button>
                    </form>
                </li> : null
        )


    }

    const checkdays = (day) => {
        const thedate = new Date(`${TableData.bookingDateYear}-${TableData.bookingDateMonth + 1}-${(day + 1).toString().padStart(2, '0')}`).getDay()

        switch (thedate) {
            case 0:
                return { backgroundColor: "red" };
            case 6:
                return { backgroundColor: "gainsboro" };
            default:
                return null
        }
    }

    return (
        <>
            <div className='row'>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Sheung Shui</th>
                            <th>Kwai Chung</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* new Date(`${parseInt(FormData.formInfo.bookingDateYear)}-${parseInt(FormData.formInfo.bookingDateMonth) + 1}-${(day + 1).toString().padStart(2, '0')}`).getDay() */}
                        {[...Array(monthsdays[TableData.bookingDateMonth])].map((x, i) =>
                            <tr key={i} style={checkdays(i)}>
                                <th>
                                    {
                                        `${parseInt(TableData.bookingDateYear)}-${parseInt(TableData.bookingDateMonth) + 1}-${(i + 1).toString().padStart(2, '0')} 
                                        (${daysch[new Date(`${TableData.bookingDateYear}-${TableData.bookingDateMonth + 1}-${(i + 1).toString().padStart(2, '0')}`).getDay()]})`
                                    }
                                </th>
                                <td><ol><ShowTheBookingDay day={i} site={16} /></ol></td>
                                <td><ol><ShowTheBookingDay day={i} site={15} /></ol></td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </>
    )
}