import { useContext, useEffect, useState } from 'react'
import { MyContext } from '../contexts/MyContext';
import RowText from '../components/rows/RowText';


function HomeDailyUpdate() {
  // $selectday = 0;
  const { getTableTypeByParam, newOnHoldBookRecord } = useContext(MyContext);
  const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
  const nowISO = theTime.substring(0, theTime.length - 5)
  const now = new Date();

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysch = ['日', '一', '二', '三', '四', '五', '六'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthsdays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


  const initialState = {
    // url: urldata.url,
    success: true,
    headdata: [],
    tabledata: [],
    // selectday: 0,
    errorMsg: '',
    successMsg: '',
  }
  // const head = [];
  // console.log(urldata)

  const [state, setState] = useState(initialState);

  // const tableUrl = ["getTableRegData.php", "ticket_booking_daily"]
  const tableUrl = ["getTableTypeByParam.php", "ticket_booking_weekly"]
  // console.log(state.selectday)

  useEffect(() => getTableDataWithParm(0), [tableUrl[0], tableUrl[1]])

  const onChangeDay = (e) => { getTableDataWithParm(e.target.value) }

  const getTableDataWithParm = (selectDay) => {
    getTableTypeByParam(tableUrl[0], tableUrl[1], selectDay)
      .then((data) => {
        // if (data != null)
        // console.log(data)
        if (data.success && data.tabledata) {
          setState({
            ...state,
            success: true,
            headdata: Object.keys(data.tabledata[0]),
            tabledata: data.tabledata,
          });
        } else {
          setState({
            ...state,
            success: false,
            errorMsg: data.message,
            headdata: [],
            tabledata: [],
          });
        }
      }
      )
  }

  const cancellist = [];
  const newlist = [];

  const cancelticket = [];
  const onholdlist = [];

  [...Array(state.tabledata.length)].map((_, i) => {
    if (parseInt(state.tabledata[i].RState) !== 1)
      parseInt(state.tabledata[i].State) === 1 ? cancellist.push(state.tabledata[i]) : newlist.push(state.tabledata[i]);
    else
      cancelticket.push(state.tabledata[i]);

  })


  const showTheBookRecord = (rowdata) => {
    return <section key={rowdata.Id} style={{ backgroundColor: parseInt(rowdata.SiteId) === 15 ? "#fff50050" : "#00ff3050" }} className='row border rounded p-1 m-1 mb-4'>
      <div className='row'>
        <div className='col'>Site: {parseInt(rowdata.SiteId) === 15 ? "Kwai Chung" : "Sheung Shui"}</div>
        <div className='col'>Last Update: {rowdata.User}</div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-2'>
          Tid:{rowdata.TicketRecordId} <br />
          Bid:{rowdata.Id}
        </div>
        <div className='col'>
          V: {rowdata.VehicleNo} <br />
          N: {rowdata.Name} <br />
          P: {rowdata.Phone}
        </div>
        <div className='col'>
          T:{rowdata.BookingTime}<br />
          S:{rowdata.SymptomFull}<br />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-3'>
          BookIng Time:
        </div>
        <div className='col'>
          {rowdata.BookingTime}
        </div>
      </div>
      {/* Under */}

      <div className='row'>
        <div className='col-3'>
          BookIng Text:<br />
        </div>
        <div id={"text_" + rowdata.Id} className='col'>
          {rowdata.VehicleNo + " " + rowdata.Name + " " + rowdata.Phone + " " + rowdata.SymptomFull}
        </div>
        <div className='row'>
          <div className='col'>
            {/* <i className="bi bi-clipboard"></i> */}
            <button className='btn btn-primary' title='Booking text' onClick={() => { navigator.clipboard.writeText(document.getElementById("text_" + rowdata.Id).textContent) }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
              </svg> copy BT
            </button>
          </div>

          {/* {console.log((new Date(rowdata.BookingDate)).getDay())} */}
          <div className='col'>
            <button className='btn btn-primary' title='WhatsApp中文信息' onClick={() => {
              navigator.clipboard.writeText(
                rowdata.SiteId == 15 ? //15 Kwai Chung
                  `預約成功通知\n車牌: ${rowdata.VehicleNo}\n日期 : ${rowdata.BookingDate} (星期${daysch[(new Date(rowdata.BookingDate)).getDay()]})\n時間: *上午9:00 點 (由於booking較滿，請客人準時，謝謝)*\n地點: *葵涌維修中心*\n\n備註: 如沒有額外維修，多數於即日可取回。如有額外維修，需要時間處理， 到時再通知取車時間。` :
                  `預約成功通知\n車牌: ${rowdata.VehicleNo}\n日期 : ${rowdata.BookingDate} (星期${daysch[(new Date(rowdata.BookingDate)).getDay()]}) 上午8:00\n地點: 上水維修中心\n\n備註: 提議客人可於早上8時左右入車 ，如定期保養沒有額外要維修，多數於即日可取回。如有額外維修，需要時間處理， 到時再通知取車時間。`
              )
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg> ws_book_zh
            </button>
          </div>

          <div className='col'>
            <button className='btn btn-primary' title='WhatsApp英文信息' onClick={() => {
              navigator.clipboard.writeText(
                rowdata.SiteId == 15 ? //15 Kwai Chung
                  `Appoinment confirmation:\nCar plate: ${rowdata.VehicleNo}\nDate: ${rowdata.BookingDate} (${days[(new Date(rowdata.BookingDate)).getDay()]})\nTime: 9:00 a.m.\nService Centre: Kwai Chung Centre\n\nRemarks: If there is no other maintenance needed, you can drive back your car within the same day.  Otherwise, will be inform by the technician later. ` :
                  `Appoinment confirmation:\nCar plate: ${rowdata.VehicleNo}\nDate: ${rowdata.BookingDate} (${days[(new Date(rowdata.BookingDate)).getDay()]})\nTime: 8:00 a.m.\nService Centre: Sheung Shui Centre\n\nRemarks: If there is no other maintenance needed, you can drive back your car within the same day.  Otherwise, will be inform by the technician later. `
              )
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg> ws_book_en
            </button>
          </div>
        </div>
      </div>
    </section>
  }

  return (
    <div className="row">
      <h1>Daily Schedule Update</h1>
      <hr />
      <div className="row">
        <div className='col-6'>
          <span>Today is </span>
          <h2>{newlist.length > 0 ?
            (new Date(Date.parse(newlist[0].UpdateDay.replace(/-/g, '/')))).getFullYear() + "-" +
            ((new Date(Date.parse(newlist[0].UpdateDay.replace(/-/g, '/')))).getMonth() + 1) + "-" +
            (new Date(Date.parse(newlist[0].UpdateDay.replace(/-/g, '/')))).getDate()
            :
            "To Day No Modify"

          }</h2>
        </div>

        <div className='col-6'>
          <RowText
            id='SelectDate'
            type='number'
            // value={FormData.formInfo.vehicleNo}
            onChange={onChangeDay}
            text='Seleted Date (0: today, 1: tomorrow, 2: day after tomorrow)'
            placeholder='0'
            defaultvalue='0'
          // required='required'
          />
        </div>
      </div>




      <hr />
      <div className='col-6'>
        <h2>New Ticket {newlist.length}</h2>
        {[...Array(newlist.length)].map((_, i) => (
          showTheBookRecord(newlist[i])
        ))}
      </div>
      <div className='col-6'>
        <h2>Cancel Ticket {cancellist.length}</h2>
        {[...Array(cancellist.length)].map((_, i) => (
          showTheBookRecord(cancellist[i])
        ))}
      </div>

      <hr />

      <div className='col-6'>
        <h2>Cancel Ticket With Book {cancelticket.length}</h2>
        {[...Array(cancelticket.length)].map((_, i) => (
          showTheBookRecord(cancelticket[i])
        ))}
      </div>

    </div>
  );
}

export default HomeDailyUpdate;
