import { useContext, useState, useEffect } from 'react';
import { Routes, Route, NavLink, useNavigate, Link } from 'react-router-dom';

import { MyContext } from '../contexts/MyContext';
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import RowText from '../components/rows/RowText';
import RowTextArea from '../components/rows/RowTextArea';
import RowCheckbox from '../components/rows/RowCheckbox';


import RowGetViewTableToSelect from "../components/RowGetViewTableToSelect"
import RowGetViewTableToSelectByText from "../components/RowGetViewTableToSelectByText"
import RowGetViewTableToCheckByText from "../components/RowGetViewTableToCheckByText"
import RowGetViewTableToDataList from "../components/RowGetViewTableToDataList"
// import ItemRadioText from '../../components/rows/RowItemRadioText';
import './form.css'
import AccessibleNavigationAnnouncer from "../components/AccessibleNavigationAnnouncer";

function SiteQuotaForm() {
    const navigate = useNavigate();
    const { siteSlotClose, getTableType, updateTableType } = useContext(MyContext);

    const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
    // const nowISO = theTime.substring(0, theTime.length - 5)
    const nowISO = theTime.split('T')[0]

    const initialFormData = {


        closedate: nowISO,
        site: 15,
        closeslot: 1,
        closeDates: [],

        errorMsg: '',
        successMsg: '',
    }
    const [FormData, setFormData] = useState(initialFormData);




    const tableUrl = ["getTableRegData.php", "site_quota"]

    useEffect(() => {

        const getSomeData = async () => {
            const data = await getTableType(tableUrl[0], tableUrl[1])
            // console.log("1", data)
            // console.log("2", data2)


            setFormData({
                ...FormData,
                closeDates: data.tabledata,
            });

        }
        getSomeData()
    }, [tableUrl[0], tableUrl[1]])


    // On Submit the Porject Form
    const submitForm = async (event) => {
        event.preventDefault();
        // console.log("submit", FormData.formInfo)
        const data = await siteSlotClose(FormData);
        console.log(data)
        if (data.success) {
            setFormData({
                ...initialFormData,
                successMsg: data.message,
            })
            navigate(0)
        }
        else {
            setFormData({
                ...FormData,
                successMsg: '',
                errorMsg: data.message
            });
        }
    }

    const onChangeValue = (e) => {
        setFormData({
            ...FormData,
            [e.target.name]: e.target.value,
        });
        // console.log(FormData)
    }


    // On Submit the Porject Form
    const deleteDate = async (event) => {
        console.log()
        event.preventDefault();
        console.log(event.target.id)
        // navigate('/ScheduleTable', { replace: true });
        const choice = window.confirm(
            "Are you sure you want to delete everything?"
        )

        if (choice) {
            // Perform your dangerous critical action here.
            // console.log("You chose to delete everything.")
            const data = await updateTableType("updateTableData.php", "site_quota", event.target.id, 1);
            if (data.success) {
                setFormData({
                    ...initialFormData,
                    successMsg: data.message,
                },)

                const choice = window.confirm("It have delete!")
                if (choice) {
                    navigate(0);
                }
            }
            else {
                setFormData({
                    ...FormData,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
            navigate(0);
        }
    }


    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (FormData.errorMsg) {
        errorMsg = <div className="error-msg">{FormData.errorMsg}</div>;
    }
    if (FormData.successMsg) {
        successMsg = <div className="success-msg">{FormData.successMsg}</div>;
    }

    const showTheDay = (siteId) => {
        return <>
            <div className='row'>
                <div className='col'>Id</div>
                <div className='col'>Date</div>
                <div className='col'>Slot</div>
                <div className='col'>Delete</div>
            </div>
            <hr />
            {FormData.closeDates.map((thedate, index) => thedate.SiteId == siteId ?
                <div className='row mb-2' key={index}>
                    <div className='col'>{thedate.Id}</div>
                    <div className='col'>{thedate.SlotDate}</div>
                    <div className='col'>{thedate.Slot}</div>
                    <div className='col'><button id={thedate.Id} onClick={deleteDate}>X</button></div>
                </div> : null
            )}
        </>
    }

    return (
        <>
            <AccessibleNavigationAnnouncer page="Site Close" />
            <h1>Site Slot</h1>
            <small>扣除Slot的數量</small>
            <div className="row p-2">
                <form onSubmit={submitForm} noValidate autoComplete='off'>

                    <div className='row mb-3'>
                        <div className='col'>
                            <label className='form-label'>維修廠<span style={{ color: "red" }}> * </span>:</label>
                            <RowGetViewTableToSelectByText
                                url="getTableTypeData.php"
                                tablename="site"
                                id="site"
                                value={FormData.site}
                                onChange={onChangeValue}
                            />
                        </div>
                        <div className='col'>
                            <RowText
                                id='closedate'
                                type='date'
                                value={FormData.closedate}
                                onChange={onChangeValue}
                                text='日期'
                            // step={1}
                            />
                        </div>
                        <div className='col'>
                            <RowText
                                id='closeslot'
                                type='number'
                                value={FormData.closeslot}
                                onChange={onChangeValue}
                                text='slot'
                            // step={1}
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        {/* <form onSubmit={submitForm} noValidate autoComplete='off'> */}
                        <button type="submit">Add Slot</button>
                        {/* <button type="reset">reset</button> */}
                        {/* </form> */}
                    </div>
                </form>
            </div>
            <div className='row'>
                <div className='col'>
                    <strong>Sheung Shui</strong>
                    {showTheDay(16)}
                </div>
                <div className='col'>
                    <strong>Kwai Chung</strong>
                    {showTheDay(15)}
                </div>
            </div>
        </>
    )

};

export default SiteQuotaForm