import { useContext, useEffect, useState, useMemo } from 'react'

import { useParams, useNavigate, Link } from 'react-router-dom';
import { MyContext } from '../contexts/MyContext'

// import useConfirm from "../components/ConfirmDialog"
import ScheduleTable from './ScheduleTable';
import AccessibleNavigationAnnouncer from "../components/AccessibleNavigationAnnouncer";

export default function Schedule() {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysch = ['日', '一', '二', '三', '四', '五', '六'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthsdays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();
    const nowISO = theTime.substring(0, theTime.length - 5)
    const now = new Date();

    const linklist = [];

    const getTheTearAndDay = (month) => {
        const themonth = now.getMonth() + month + 1
        return now.getFullYear() + (themonth > 12 ? 1 : 0) + '-' + (themonth > 12 ? themonth - 12 : themonth)
    }

    [...Array(12)].map((_, i) => (

        linklist.push(
            <Link to={`/schedule/${getTheTearAndDay(i)}`}>
                {getTheTearAndDay(i)}
            </Link>
        )
    ))


    // console.log(linklist)


    return (
        <>
            <AccessibleNavigationAnnouncer page="Schedule" />

            <div className='row m-2'>
                {[...Array(12)].map((_, i) => (
                    <div className='col-2' key={i} >{linklist[i]}</div>
                ))}
            </div>

            <div className='row'>
                <ScheduleTable />
                {/* <ScheduleTableV2 /> */}
            </div>
        </>
    )
}