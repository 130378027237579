import { useContext, useEffect, useState } from 'react'
import { MyContext } from '../contexts/MyContext'
import RowSelect from './rows/RowSelect'
import RowDataList from './rows/RowDataList';

function RowGetViewTableToDataList(urldata) {
    const { getTableType } = useContext(MyContext);

    const initialState = {
        // url: urldata.url,
        success: true,
        headdata: [],
        tabledata: [],
        errorMsg: '',
        successMsg: '',
    }
    // const head = [];
    // console.log(urldata)

    const [state, setState] = useState(initialState);

    useEffect(() => getTableType(urldata.url, urldata.tablename)
        .then((data) => {
            // if (data != null)
            // console.log(data)
            if (data.success && data.tabledata) {
                setState({
                    ...state,
                    success: true,
                    headdata: Object.keys(data.tabledata[0]),
                    tabledata: data.tabledata,
                });
            } else {
                setState({
                    ...state,
                    success: false,
                    errorMsg: data.message
                });
            }
        }), [urldata])

    // console.log(state)

    // let head = [
    //     'ID',
    //     'First Name',
    //     'date'
    // ]   

    // Object.keys(state.tabledata[0]).map(key => head.push(key))

    return (state.success) ? <RowDataList
        head={state.headdata}
        data={state.tabledata}
        id={urldata.id} text={urldata.text}
        value={urldata.value}
        onChange={urldata.onChange} /> : <div>{state.errorMsg}</div>



};

export default RowGetViewTableToDataList
